import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="company-locations"
export default class extends Controller {
  static targets = ["mapContainer"];
  static values = { geoData: Array };

  connect() {
    // map tile layer
    let osm = L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
      maxZoom: 19,
      attribution:
        '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
    });

    // initialise company location layers
    let companyLocationsGroup = new L.FeatureGroup();

    // initialise map with layers
    let map = L.map(this.mapContainerTarget, {
      center: [12.898, 77.581],
      zoomControl: false,
      zoom: 12,
      layers: [osm],
    });

    // add layers to the map
    map.addLayer(companyLocationsGroup);

    // iterate over companies array and add markers and parking radius circle
    this.geoDataValue.forEach((element) => {
      let fillColor;
      let coords = [element[0], element[1]];

      // set fill color based on archived status
      if (element[3] == true) {
        fillColor = "#6b7280 ";
      } else {
        fillColor = "#16a34a";
      }

      // set circle with 1km radius
      let circle = L.circle(coords, {
        color: fillColor,
        weight: 2,
        dashArray: "4",
        fillColor: fillColor,
        fillOpacity: 0.2,
        radius: 1000,
      });

      // set marker with company name popup
      let locationName =
        element[3] == true ? `${element[2]} (archived)` : element[2];
      let marker = L.marker(coords, {
        opacity: element[3] == true ? 0.6 : 1,
        riseOnHover: true,
      })
        .bindTooltip(locationName, { direction: "top" })
        .on("mouseover", () => {
          circle.addTo(map);
        })
        .on("mouseout", () => {
          circle.removeFrom(map);
        });

      companyLocationsGroup.addLayer(marker);
    });

    // fit map to location bounds
    map.fitBounds(companyLocationsGroup.getBounds(), { padding: [5, 5] });
  }
}
